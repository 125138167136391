@use '../includes' as *;

.about_this_video {
  display: grid;
  gap: $g8;

  button[class*='MyList'] {
    grid-area: mylist;
    width: 25px;

    @include breakpoint($sm) {
      width: 36px;
    }
  }
}

.ancestor_link {
  @include over-title;

  grid-area: ancestor;
  width: 100%;
  margin-block-end: $g8;

  @include breakpoint($md) {
    margin-block-end: $g16;
  }
}

.ancestor_logo {
  width: clamp(100px, 20vw + 1em, 200px);
}

.video_title {
  grid-area: title;
  color: $white;
  font-size: clamp($f14, 1.2vw + 1em, $f20);
  font-weight: $bold;
  line-height: 1.1;
}

.description_short,
.description_long,
.video_summary,
.video_airing_expiring_rating_info {
  @include body-copy-3($pebble);

  @include breakpoint($lg) {
    @include body-copy-1;

    color: var(--text-color);
  }
}

.video_summary {
  display: flex;
  grid-area: summary;

  svg {
    width: 15px;
    margin-inline-end: $g8;
  }
}

.description_short {
  grid-area: description;
}

.description_long {
  grid-area: description;
}

.video_airing_expiring_rating_info {
  grid-area: airing;
}

a.see_all_videos_with_audio_description {
  @include body-copy-1;

  grid-area: seeAllVideosWithAudioDescription;
  font-weight: $bold;

  @include breakpoint($below-lg) {
    @include body-copy-3($pebble);

    font-weight: $bold;

    svg {
      fill: $pebble;
    }
  }
}

.video_franchise {
  grid-area: franchise;
}

// responsive behavior
// the visibility of different elements at different breakponts is ... complicated
.above_tabs {
  grid-template-areas:
    'ancestor                         .'
    'title                       mylist'
    'summary                          .'
    'description                      .'
    'airing                           .'
    'seeAllVideosWithAudioDescription .'
    'franchise                        .';
  grid-template-columns: 1fr 25px;
  padding-block-start: $g16;
  padding-inline: var(--spacing-inline);

  @include breakpoint($sm) {
    grid-template-columns: 1fr 36px;
    padding-block-start: $g32;
  }

  @include breakpoint($md) {
    display: none;
  }

  .description_short {
    @include breakpoint($sm) {
      display: none;
    }
  }

  .description_long {
    display: none;

    @include breakpoint($sm) {
      display: block;
    }
  }
}

.in_tab {
  display: flex;
  flex-direction: column;

  // below md hide everything except for description_long
  > *:not(.description_long) {
    @include breakpoint($below-md) {
      display: none;
    }
  }

  // hide description_long at $sm
  .description_long {
    @include breakpoint($sm-only) {
      display: none;
    }
  }

  // above $md everything renders
}
